<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-body">
                <h6>
                    <span class="bd-content-title">Pencarian<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#quick-start" style="padding-left: 0.375em;"></a></span>
                </h6>
                <div class="form-group">
                    <input id="query" class="form-control form-control-lg" v-model="form.query" type="text" required="required" placeholder="Ketik nomor Resi atau nomor Pengiriman" @keyup.enter="checkEnter" name="query" 
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==189 && event.shiftKey==false) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                    onkeyup="return console.log(event.keyCode )"/>
                </div>
                <router-link  v-if="foot_link" to="/riwayat/admin">Lihat semua transaksi Outstanding <i class="fa fa-list-ul"></i></router-link>
                <span v-if="foot_result">Hasil pencarian {{ data.length }} record</span>
                <div class="mt-5" v-if="foot_result">
                    <div class="input-group border mb-3" v-for='item in data'>
                        <div style="min-height: 50px; flex: 1 1 auto;width: 1%;" class="w-auto p-3">
                            <div class="row">
                                <div class="col-md-2 col-sm-12">
                                    <img :src="getKurirLogo(item.kurir)" style="height:30px;" />
                                </div>
                                <div class="col-md-2 col-sm-12">
                                    <div class="description-block text-left mt-0">
                                        <h5 class="description-header">Nomor</h5>
                                        <span class="description-text">{{ item.no }}</span>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-12">
                                    <div class="description-block text-left mt-0">
                                        <h5 class="description-header">Tujuan</h5>
                                        <span class="description-text">{{ item.dest }}</span>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-12">
                                    <div class="description-block text-left mt-0">
                                        <h5 class="description-header">Tanggal</h5>
                                        <span class="description-text">{{ item.date }}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="description-block text-left mt-0">
                                        <h5 class="description-header">Status</h5>
                                        <span class="description-text">{{ item.pickup_status == 0 ? item.status == 1 ? 'Error' : 'Draft' : item.tracking_status == 0 && item.deleted == 1 ? 'Cancel' : item.tracking_status == 0 && item.order_type == 1 ? 'Menunggu Drop' : item.tracking_status == 0 && item.order_type == 0 ? 'Menunggu Pickup' : item.tracking_status == 1 ? 'Perjalanan' : item.tracking_status == 2 ? 'Terkirim' : item.tracking_status == 3 ? 'Proses Retur' : item.tracking_status == 4 ? item.return_received == 0 ? 'Retur Diserahkan' : 'Retur Diterima' : item.tracking_status == 5 ? 'Paket Bermasalah' : '' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="input-group-append">
                            <button type="button" class="btn btn-info btn-flat" @click="detail2(item.no)"><i class="fa fa-search"></i> Detail</button>
                        </span>
                    </div>
                </div>
                <div class="mt-3" v-if="foot_detail">
                    <div class="card elevation-2">
                        <div class="card-body">
                            <img :src="getKurirLogo(detailData.data.prefix)" style="height:30px;" />
                            <button class="btn btn-success ml-4" @click="back" style="float: right;"><i class="fas fa-arrow-circle-left"></i> Kembali</button>
                            <h3>Informasi Pengiriman</h3>
                            <table style="width: 50%">
                                <tr>
                                    <td>No Resi</td>
                                    <td width="10%"> : </td>
                                    <td>{{ detailData.data.noResi }}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td> : </td>
                                    <td>{{ detailData.data.courierStatus }}</td>
                                </tr>
                                <tr>
                                    <td>Dikirim tanggal</td>
                                    <td> : </td>
                                    <td>{{ detailData.data.createdon }}</td>
                                </tr>
                                <tr>
                                    <td>Dikirim oleh</td>
                                    <td> : </td>
                                    <td>{{ detailData.data.senderName }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>{{ detailData.data.senderCity }}</td>
                                </tr>
                                <tr>
                                    <td>Dikirim ke</td>
                                    <td> : </td>
                                    <td>{{ detailData.data.receiverName }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>{{ detailData.data.receiverCity }}</td>
                                </tr>
                            </table>
                            <h3>Status Pengiriman</h3>
                            <table style="width: 50%">
                                <tr>
                                    <td>Tanggal</td>
                                    <td>Keterangan</td>
                                    <td>Keterangan</td>
                                </tr>
                                <tr v-for='item in detailData.items'>
                                    <td>{{ item.changedOn }}</td>
                                    <td>{{ item.note }}</td>
                                    <td>{{ item.trackingStatus }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';

export default {
    name: 'Checktracking',
    data() {
        return {
            errors: [],
            foot_link: true,
            foot_result: false,
            foot_detail: false,
            method: '',
            roles: '',
            data: [],
            formTitle: 'Tambah User',
            form: {
                query: '',
            },
            detailData: []
        }
    },
    computed: {},
    created: function() {

    },
    methods: {
        getKurirLogo(kurir) {

            const fileName = kurir.toLowerCase();

            return require(`../assets/img/${fileName}.png`) // the module request
        },
        checkAll() {
            this.findData();
            this.foot_link = false;
            this.foot_detail = false;
            this.foot_result = true;
        },
        checkEnter(object) {
            if (this.form.query) {
                this.findData(this.form.query);
                this.foot_link = true;
                this.foot_result = true;
                this.foot_detail = false;
            }
            object.preventDefault()
        },
        detail: function (idx) {
            this.foot_link = true;
            this.foot_detail = true;
            this.foot_result = false;
            var urlSubmit = '/order/tracking/cek_resi/' + idx;
            authFetch(urlSubmit, {
                    method: 'GET',
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {

                    }
                    return res.json();
                })
                .then(js => {
                    console.log(js)
                    if (js) {
                        this.detailData = js;
                    }
                });
        },
        detail2: function (idx) {
            this.$router.push({ path: '/order/detail/' + idx })
        },
        back() {
            this.foot_link = true;
            this.foot_result = true;
            this.foot_detail = false;
        },
        findData: function(id='') {
            var urlSubmit = '/order/tracking/' + id;
            authFetch(urlSubmit, {
                    method: 'GET',
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {

                    }
                    return res.json();
                })
                .then(js => {
                    console.log(js)
                    if (js) {
                        this.data = js;
                        if(this.data.length>0)this.foot_link = false;
                        else this.foot_link = true;
                    }
                });
        }
    },
    mounted() {
        $('.loading-overlay').removeClass('show');
    }
}
</script>